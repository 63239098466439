<template>
  <div class="partner-home">
    <div class="app-loader" v-if="businessFetchWaiting">
      <span class="app-loader__message">Loading your businesses...</span>
      <v-progress-circular
        class="mx-auto"
        :size="70"
        :width="7"
        indeterminate
      ></v-progress-circular>
    </div>
    <div class="partner-home__container" v-else-if="business">
      <v-row>
        <v-col col="3">
          <v-btn text @click="goBack">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>
        <!-- <v-col col="12" v-if="activeRealm.id=='zobaze'">
          <div class="actions">
            <v-btn @click="openBusinessInMaster"
            >Open in Master <v-icon class="ml-2"> mdi-open-in-new</v-icon></v-btn
            >
          </div>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col col="12">
          <v-card v-if="business" title="View Business">
            <v-card-title>Business Details</v-card-title>
            <v-list class="transparent">
              <v-list-item>
                <v-list-item-title> User Id </v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  {{ user.id }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title> Business Id </v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  {{ business.id }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title> Name </v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  {{ business.name }}
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> Email </v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  {{ business.email }}
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> Phone </v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  {{ business.phone }}
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> Address </v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  {{ business.address }}
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> Current Plan </v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  <div>{{ business.activeProductId || "" }}</div>
                  <div>{{ business.activePlanId || "None" }}</div>

                  <div class="text-small" v-if="business.activePlanExpiry">
                    expires:
                    {{ getFormattedDate(business.activePlanExpiry) }}
                  </div>
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> Country </v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  {{ business.country }}
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> Business Created </v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  <div>{{ getFormattedDate(business.createdDate) }}</div>
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> Staff count </v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  <div>{{ business.staffCount }}</div>
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> Customer count </v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  <div>{{ business.customerCount }}</div>
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> Total Value </v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  <div v-if="business.totalAmount">
                    {{ business.currencyCode }} {{ business.totalAmount }}
                  </div>
                  <div v-else>{{ business.currencyCode }} 0</div>
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> Business Created </v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  <div>{{ getFormattedDate(business.createdDate) }}</div>
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  computed: {
    ...mapGetters({
      businessFetchWaiting: "partnerBusiness/businessFetchWaiting",
      businessFetchError: "partnerBusiness/businessFetchError",
      businessList: "partnerBusiness/businessList",
      account: "partner/active",
      user: "user/user",
    }),
    currentBusinessId() {
      return this.$route.params.bId || null;
    },
    business() {
      return this.businessList.find((x) => x.id === this.currentBusinessId);
    },
    isWhitelabel() {
      return this.account.type == "whitelabel";
    },
  },
  methods: {
    ...mapActions({
      fetchBusinesses: "partnerBusiness/fetchBusinesses",
    }),
    getFormattedDate(date) {
      return date ? moment(date).format("ll") : "-";
    },
    reloadBusinessDetails() {
      this.fetchBusinesses({
        activeAccount: this.account,
        bid: null,
        options: null,
    });
    },
    reportBusiness() {
      if (window.HelpCrunch) {
        // window.HelpCrunch("updateUserData", {
        //   currentPage: "Report Business",
        //   businessIdToReport: this.business.id,
        //   businessName: this.business.name,
        //   businessPlan: this.business.activePlanId,
        //   activeProductId: this.business.activeProductId,
        //   businessCreatedAt: this.getFormattedDate(this.business.createdDate),
        // });
        window.HelpCrunch("openChat");
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    openBusinessInMaster() {
      this.$router.push({
        path: `/master/business/${this.business.id}`,
      });
    }
  },
};
</script>

<style lang="scss">
.home {
  min-height: 100%;
}

.centered-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.actions {
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 0.33rem;
  }
}
</style>
